
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { getShopPropmodelconfList } from "@/api/request/item";

//组件
@Component({
  name: "ItemProp",
})

//界面函数
export default class extends Vue {
  //定义比那辆
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //道具操作类型
  private propOpType = [
    { type: 0, name: "不可设置" },
    { type: 1, name: "可设置" },
  ];

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopPropmodelconfList({});

    //数据赋值
    this.list = data;
    this.total = data ? data.length : 0;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取类型名称
  private getPropOpTypeName(type: number): string {
    for (let idx = 0; idx < this.propOpType.length; idx++) {
      const data = this.propOpType[idx];
      if (data.type === type) {
        return data.name;
      }
    }
    return "--";
  }

  //获取类型颜色
  private getPropOpTypeColor(type: number): string {
    return type === 0 ? "#1f2d3d" : "#448EF7";
  }
}
